<template>
    <v-form>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="dealer.name"
            label="Dealer Name"
            required
          ></v-text-field>
        </v-col>
        <v-btn @click="addDealer">Add</v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
    name: "AddDealer",
    data() {
        return {
            dealer: {
                name: "",
                code: ""
            }
        }
    },
    methods: {
        async addDealer() {
            try {
                await this.$store.dispatch("dealer/createDealer", {
                    name: this.dealer.name,
                    code: this.createCode()
                })
                this.dealer = {}
            } catch (error) {
                console.log(error)
            }
      },
      createCode() {
        return Math.floor(100000 + Math.random() * 900000)
      },
      capitalize(item){
        return item.slice(0,1).toUpperCase() + item.slice(1)
      }
    }
}
</script>

<style lang="scss" scoped>

</style>