<template>
  <v-card class="dealers">
    <v-card-title>
      <h2>Dealers</h2>
        <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
    @update:page="goTo"   
    :headers="dealerHeaders"
    :items="dealers"
    class="data-table elevation-1"
    :items-per-page="50"
    :footer-props="{
    'items-per-page-options': [10, 25, 50, 100]
    }"
    :loading="loading"
    :search="search"
    loading-text="Loading Dealers..."
    :no-data-text="noDealers"
    @click:row="routeTo"
    height="70vh"
    fixed-header
  >
  </v-data-table>
  <!-- <AddDealer /> -->
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import AddDealer from "@/components/AddDealer"
export default {
  name: 'Dealers',
  async created() {
    if (!this.dealers.length && this.token) {
      this.loading = true
      await this.$store.dispatch("dealer/getCRMDealers")
      this.dataCheck = true
      this.loading = false
    }
  },
  components: {
    AddDealer
  },
  data() {
      return {
          loading: false,
          dataCheck: false,
          search: "",
          dealerHeaders: [
            {
                text: "Name",
                value: "pfs_name"
            },
            {
                text: "Phone",
                value: "pfs_businessphone"
            },
            {
                text: "Address",
                value: "pfs_street1"
            },
            {
                text: "City",
                value: "pfs_city"
            },
            {
                text: "State",
                value: "pfs_state"
            },
            {
                text: "Zip",
                value: "pfs_zipcode"
            },
            {
                text: "Dealer Code",
                value: "pfs_dealercode"
            },
        ]
      }
  },
  watch: {
    async token(val) {
      this.loading = true
      await this.$store.dispatch("dealer/getCRMDealers")
      this.dataCheck = true
      this.loading = false
    }
  },
  computed: {
    ...mapState("dealer", ["dealers"]),
    ...mapState("user", ["token"]),
    noDealers() {
        if (this.dataCheck) {
            return "No Dealers Found"
        }
        else {
            return "Loading Dealers..."
        }
    }
  },
  methods: {
    routeTo(item) {
      this.$router.push(`/dealers/${item.pfs_dealerid}/customers`)
    },
    goTo () {
    this.$vuetify.goTo(
      '.data-table tbody tr',
        {
          offset: 50,
          container: '.v-data-table__wrapper'
        }
      )
  }
  }
  
}
</script>

<style lang="scss" scoped>
  .dealers {
    ::v-deep  tr {
        cursor: pointer;
    }
  }
</style>